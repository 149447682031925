import PropTypes from "prop-types";
import React, { useState, useContext, useEffect, useRef } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import gsap from "gsap";
import { Link } from "../components/links";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import ReactContext from "../context/reactContext";
import classNames from "classnames";
import Lenis from "@studio-freight/lenis";
// import { GlobalCursor } from "../components/custom-cursor";
import useIsDesktop from "../lib/useIsDesktop";
import { ThemeContext } from "../context/themeContext";
import { StaticImage } from "gatsby-plugin-image";
import { useCheckScreenSize } from "../lib/checkScreenSize";
gsap.registerPlugin(ScrollTrigger);

const LandingPageLayout = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const { pathname } = location;

  const [modalComponent, setModalComponent] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);

  const headerRef = useRef(null);
  const floatingButtonRef = useRef(null);

  const themeContext = useContext(ThemeContext);

  const bodyClass = themeContext.dark ? "dark" : "light";
  const isTablet = useCheckScreenSize(1200);
  const isDesktop = useIsDesktop();

  const hamburgerClassses = classNames("floating-nav__button", {
    "floating-nav__button--active": menuActive,
  });
  const floatingNavClasses = classNames("floating-nav", {
    "floating-nav--active": showFloatingButton && !menuActive,
    "floating-nav--active--open": showFloatingButton && menuActive,
  });
  const floatingLogoClasses = classNames("rc-floating-logo", {
    "rc-floating-logo--active": showFloatingButton && !menuActive,
  });

  const overlayClasses = classNames("nav-overlay", {
    "nav-overlay--active": menuActive,
  });

  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0.1,
      duration: 1,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      smoothWheel: true,
    });
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    const rafId = requestAnimationFrame(raf);

    return () => {
      (lenis).off("scroll");
      cancelAnimationFrame(rafId);
    };
  }, [menuActive]);

  useEffect(() => {
    document.body.className = themeContext.dark ? "dark" : "";
  }, [themeContext.dark]);

  useEffect(() => {
    if (isTablet) {
      gsap.set(headerRef.current, {
        y: -headerRef.current.offsetHeight,
      });
      setShowFloatingButton(true);
      return;
    }

    if (headerRef.current && !isTablet) {
      ScrollTrigger.create({
        start: "top+=150 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onEnter: () => {
          gsap.to(headerRef.current, {
            y: -headerRef.current.offsetHeight,
            duration: 0.1,
            delay: 0.25,
            ease: "power1.inOut",
            onComplete: () => setShowFloatingButton(true),
          });
        },
      });

      // Trigger for scrolling up
      ScrollTrigger.create({
        start: "top+=100 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onLeaveBack: () => {
          gsap.to(headerRef.current, {
            y: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
              setShowFloatingButton(false);
              setMenuActive(false);
            },
          });
        },
      });
    }
  }, [setShowFloatingButton, setMenuActive, isTablet, pathname]);

  const seoData = seoDataObj[pathname] ||
    seoDataObj[pathname.substr(0, pathname.length - 1)] || {
      title,
      description,
      meta,
    };

  return (
    <ReactContext.Provider
      value={{
        menuActive,
        setMenuActive,
      }}
    >
      <div className={`rc-site ${bodyClass}`}>
        {seoData && <SEO {...seoData} />}
        <Header forwardRef={headerRef} location={location} />
        <Link to="/" className={floatingLogoClasses}>
          <StaticImage
            src="../images/rawColorLogoR.png"
            alt="raw color small logo"
          />
        </Link>
        <div
          ref={floatingButtonRef}
          className={floatingNavClasses}
          onClick={() => setMenuActive(!menuActive)}
        >
          <button
            onClick={() => setMenuActive(!menuActive)}
            className={hamburgerClassses}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="rc-main">{children}</div>
        <Footer location={location} />
      </div>
      {modalComponent}
      {/* <GlobalCursor pathname={pathname}/> */}
      <div className="static"></div>
      <span className={overlayClasses}></span>
    </ReactContext.Provider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withLocation(LandingPageLayout);
