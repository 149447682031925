export const navLinks = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Work",
    url: "/work",
  },
  // {
  //   title: "About",
  //   url: "/about",
  // },
  {
    title: "Contact",
    url: "/contact",
  },
];
