export const footerLinks = [
  {
    title: "Work",
    link: "/work",
    isOutbound: false,
  },
  {
    title: "Contact",
    link: "/contact",
    isOutbound: false,
  },
  // {
  //   title: "About",
  //   link: "/about",
  //   isOutbound: false,
  // },
  {
    title: "Home",
    link: "/",
    isOutbound: false,
  },
];
