export const seoDataObj = {
  "": {
    description:
      "Raw Color Studios is an award-winning film and content production company with roots in Los Angeles and the SF Bay Area. We firmly believe that storytelling must evolve to mirror our ever-changing world, one that constantly innovates as new technologies redefine the way we live our lives.",
    title: "Raw Color Film Studios",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.rawcolorstudios.com/RAW+COLOR+BANNER.png",
  },
  "/contact": {
    description:
      "Get in touch with us at Raw Color Studios. Looking to collaborate on a project? We'd love to hear from you.",
    title: "Contact us",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.rawcolorstudios.com/RAW+COLOR+BANNER.png",
  },
  "/work": {
    description:
      "Explore our portfolio at Raw Color Studios. From compelling documentaries to cutting-edge commercial content, see how our award-winning production techniques and creative storytelling are making an impact across Los Angeles and the SF Bay Area.",
    title: "Explore our work",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.rawcolorstudios.com/RAW+COLOR+BANNER.png",
  },
};
