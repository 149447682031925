import React from "react";

export const colorizer = (text, start = 0, mono = null) => {
  const colors = ["#a2001c", "#f2b323", "#005239", "#15307e", "#96317f"];
  const splitText = text.split("");

  return splitText.map((letter, index) => (
    <span
      key={index}
      style={{
        color:
          letter.trim() !== ""
            ? mono || colors[(index + start) % colors.length]
            : "transparent",
        width: letter.trim() !== "" ? "auto" : "1em",
      }}
    >
      {letter}
    </span>
  ));
};
