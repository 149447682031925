import React from "react";
import { Link } from "../links";
import { footerLinks } from "../../data/components/footer";
import "./footer.scss";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer className="rc-footer">
      <div className="rc-footer__inner">
        <div className="rc-footer__logo">
          <StaticImage src="../../images/Logo.png" alt="Logo" />
        </div>
        <p className="rc-footer__meta-description">
          Raw Color Studios is an independent production company based in LA.
          We’re a fast-growing collective of young filmmakers who create with
          the philosophy of “Art Before All.” We’ve collaborated with over a
          hundred artists to produce 10 shorts over the last two years.
        </p>
        <div className="rc-footer__links">
          {footerLinks.map((item) => {
            const { title, link, isOutbound } = item;
            const isDownload = title === "Resume" ? true : false;
            return (
              <div
                key={title}
                className="rc-footer__links__link cursor-pointer"
              >
                <Link isOutbound={isOutbound} download={isDownload} to={link}>
                  {title}
                  <span className="rc-footer__links__link__span"></span>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="rc-footer__social">
          <div className="rc-footer__social__inner">
            <p>Social</p>
            <div className="rc-footer__social__links">
              <div className="rc-footer__social__link cursor-pointer">
                <Link
                  isOutbound
                  to="https://www.instagram.com/rawcolorstudios/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                    />
                  </svg>
                </Link>
              </div>
              <div className="rc-footer__social__link cursor-pointer">
                <Link isOutbound to="https://www.youtube.com/@rawcolorstudios">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m10 15l5.19-3L10 9zm11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="rc-footer__lower">
          <p>2024 © Raw Color Studios. All Rights Reserved.</p>
          {/* <Link to="/terms-and-conditions">Terms & Conditions</Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
