import React, { useRef, useState, useEffect, useContext } from "react";
import { Link } from "../links";
import gsap from "gsap";
import classNames from "classnames";
import { navLinks } from "../../data/components/header";
import { useLocation } from "@reach/router";
import ReactContext from "../../context/reactContext";
import CTA from "../Button";
import "./header.scss";
import { StaticImage } from "gatsby-plugin-image";
import { colorizer } from "../../lib/colorizer";
import { useCheckScreenSize } from "../../lib/checkScreenSize";

const Header = React.forwardRef((props, ref) => {
  const location = useLocation();
  const [linksMenuOpen, setLinksMenuOpen] = useState(false);
  const { menuActive, setMenuActive } = useContext(ReactContext);
  const { pathname } = location;
  const linksRef = useRef(null);
  const transitionImageRef = useRef(null);

  const linksClasses = classNames("rc-header__links", {
    "rc-header__links--open": linksMenuOpen,
  });
  const linksIndexClasses = classNames("rc-header__links__index", {
    "rc-header__links__index--open": linksMenuOpen,
  });

  const mobileNavClasses = classNames("rc-header__mobile-nav", {
    "rc-header__mobile-nav--active": menuActive,
  });

  const isMobileSmall = useCheckScreenSize(600);
  const isTablet = useCheckScreenSize(1200);

  useEffect(() => {
    const tl1 = gsap.timeline();
    const tl2 = gsap.timeline();
    if (menuActive) {
      if (!isMobileSmall) {
        setLinksMenuOpen(true);
        gsap.fromTo(
          linksRef.current,
          {
            top: "100vh",
            y: 88,
            height: 0,
          },
          {
            top: 0,
            height: "100vh",
            duration: 0.75,
            delay: 0.2,
            ease: "power1.out",
          }
        );
        tl1.fromTo(
          transitionImageRef.current,
          {
            top: "100vh",
            y: 88,
            height: 0,
          },
          {
            top: 0,
            height: "100vh",
            duration: 0.5,
            delay: 0,
            ease: "power1.out",
          }
        );
        tl1.to(transitionImageRef.current, {
          y: 0,
          height: 0,
        });
      }
    } else {
      if (!isMobileSmall) {
        tl2.fromTo(
          transitionImageRef.current,
          {
            top: "100vh",
            y: 88,
            height: 0,
          },
          {
            top: 0,
            height: "100vh",
            duration: 0.5,
            delay: 0,
            ease: "power1.out",
          }
        );
        tl2.to(transitionImageRef.current, {
          y: 0,
          height: 0,
          duration: 0.5,
          onComplete: () => setLinksMenuOpen(false),
        });
        gsap.to(linksRef.current, {
          duration: 0.75,
          y: 0,
          height: "auto",
        });
      }
    }
  }, [menuActive]);

  return (
    <header ref={props.forwardRef} className="rc-header">
      <div ref={transitionImageRef} className="rc-header__transition-image">
        {linksMenuOpen && (
          <StaticImage
            src="../../images/bannerTablet.png"
            alt="transition image"
            quality={50}
            formats={["AUTO", "WEBP", "AVIF"]}
            width={1816}
            height={1507}
            loading="eager"
          />
        )}
      </div>
      <div className="rc-header__inner">
        {!menuActive && (
          <Link className="rc-header__logo" to="/">
            <StaticImage src="../../images/Logo.png" alt="Raw Color Logo" />
          </Link>
        )}
        <div ref={linksRef} className={linksClasses}>
          <div className="rc-header__links__inner">
            {linksMenuOpen && (
              <div className="rc-header__links__menu-logo">
                <StaticImage
                  src="../../images/rawColorLogoR.png"
                  alt="rawColor Logo"
                  quality={50}
                />
              </div>
            )}
            <div className={linksIndexClasses}>
              {navLinks.map((item) => {
                const { title, url } = item;
                const navLinkClasses = classNames("rc-header__nav-link", {
                  "rc-header__nav-link--active": pathname === url,
                });
                const menuLinkClasses = classNames(
                  "rc-header__nav-link__menu-item",
                  {
                    "rc-header__nav-link__menu-item--active": pathname === url,
                  }
                );
                return (
                  <div
                    key={title}
                    onClick={() => {
                      if (menuActive) {
                        setMenuActive(false);
                      }
                    }}
                    className={navLinkClasses}
                  >
                    {!menuActive && <Link to={url}>{title}</Link>}
                    {menuActive && (
                      <Link to={url}>
                        <div className={menuLinkClasses}>
                          <p className="rc-header__nav-link__menu-item__colorized">
                            {colorizer(title)}
                          </p>
                          <p>{title}</p>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={mobileNavClasses}>
            <h5>Navigation</h5>
            {navLinks.map((item) => {
              const { title, url } = item;
              const navLinkClasses = classNames(
                "rc-header__mobile-nav__link",
                {
                  "rc-header__mobile-nav__link--active": pathname === url,
                }
              );
              return (
                <div
                  key={title}
                  onClick={() => {
                    if (menuActive) {
                      setMenuActive(false);
                    }
                  }}
                  className={navLinkClasses}
                >
                  <Link to={url}>{title}</Link>
                </div>
              );
            })}
          </div>
          {menuActive && (
            <div className="rc-header__links__socials">
              <div>
                <Link
                  isOutbound
                  to="https://www.instagram.com/rawcolorstudios/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="var(--white)"
                      d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                    />
                  </svg>
                </Link>
                <Link isOutbound to="https://www.youtube.com/@rawcolorstudios">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                      <path
                        fill="var(--white)"
                        d="M12 4c.855 0 1.732.022 2.582.058l1.004.048l.961.057l.9.061l.822.064a3.802 3.802 0 0 1 3.494 3.423l.04.425l.075.91c.07.943.122 1.971.122 2.954c0 .983-.052 2.011-.122 2.954l-.075.91c-.013.146-.026.287-.04.425a3.802 3.802 0 0 1-3.495 3.423l-.82.063l-.9.062l-.962.057l-1.004.048A61.59 61.59 0 0 1 12 20a61.59 61.59 0 0 1-2.582-.058l-1.004-.048l-.961-.057l-.9-.062l-.822-.063a3.802 3.802 0 0 1-3.494-3.423l-.04-.425l-.075-.91A40.662 40.662 0 0 1 2 12c0-.983.052-2.011.122-2.954l.075-.91c.013-.146.026-.287.04-.425A3.802 3.802 0 0 1 5.73 4.288l.821-.064l.9-.061l.962-.057l1.004-.048A61.676 61.676 0 0 1 12 4Zm-2 5.575v4.85c0 .462.5.75.9.52l4.2-2.425a.6.6 0 0 0 0-1.04l-4.2-2.424a.6.6 0 0 0-.9.52Z"
                      />
                    </g>
                  </svg>
                </Link>
                <Link isOutbound to="https://www.tiktok.com/@rawcolorstudios">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="var(--white)"
                      d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48z"
                    />
                  </svg>
                </Link>
              </div>
              <Link
                isOutbound
                to="https://www.danielrbogart.com/"
                className="rc-header__links__socials__author"
              >
                Code/Design by Daniel
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
});

export default Header;
