import React, { useEffect, useRef, useState } from "react";
import { Link } from "../links";
import gsap from "gsap";
import classNames from "classnames";
import "./cta.scss";

const CTA = ({
  children,
  to,
  isOutbound = false,
  className,
  glow = false,
  theme,
  submitted = false,
  button = false,
}) => {
  const waterFillRef = useRef(null);
  const ctaRef = useRef(null);
  const [tl, setTimeline] = useState(null);

  const ctaClasses = classNames("rc-cta", {
    "rc-cta--hollow": theme === "hollow",
    "rc-cta--black": theme === "black",
    "rc-cta--submitted": submitted,
  });

  useEffect(() => {
    const waterFillElement = waterFillRef.current;
    const ctaElement = ctaRef.current;

    if (!submitted) {
      const waterFillAnimation = gsap
        .timeline({ paused: true })
        .to(waterFillElement, {
          duration: 0.5,
          scaleX: 1,
          ease: "power2.out",
          x: -38,
        });
      setTimeline(waterFillAnimation);
    }

    if (glow) {
      gsap.to(ctaElement, {
        backgroundPosition: "300px 0px",
        duration: 1,
        repeat: -1,
        yoyo: true,
        repeatDelay: 4,
      });
    }
  }, [glow]);

  const handleMouseOver = () => {
    if (tl && !submitted) {
      tl.timeScale(1).play();
    }
  };

  const handleMouseLeave = () => {
    if (tl) {
      tl.timeScale(2).reverse();
    }
  };

  const buttonCTA = button ? (
    <button
      ref={ctaRef}
      className={`${ctaClasses} ${className} ${glow ? "glow" : ""}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      type="submit"
    >
      <a>{children}</a>
      <div ref={waterFillRef} className="rc-cta__water-fill"></div>
    </button>
  ) : (
    <div
      ref={ctaRef}
      className={`${ctaClasses} ${className} ${glow ? "glow" : ""}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Link isOutbound={isOutbound} to={to}>
        {children}
      </Link>
      <div ref={waterFillRef} className="rc-cta__water-fill"></div>
    </div>
  );

  return <>{buttonCTA}</>;
};

export default CTA;
